import { cloneDeep } from "lodash";
import React, { useEffect } from "react"
import { useState } from "react";
import ReactTollTip from "../../../../components/Table/Cellrenderer/ReactToolTip";
import ReactTable from "../../../../components/Table/ReactTable";
import WithDynamicPlugins from "../../../../components/Table/WithPlugins";
import ColumnFilter from "../../../UserManagement/ColumnFilter";
import { TABLE_HEADERS } from "../NewStoreSetupList/NewStoreListConstants";

const ReactTableWithPlugins = WithDynamicPlugins(ReactTable);

const DetailsTable = (props) => {
    const { data, setSelectedSisterStores, setDcSrcRowsSelection, saveDCSource } = props
    const [sisterStoreData, setData] = useState([])
    const [initialSelectedRows, setInitialSelectedRows] = useState({})
    const [dcDetails, setDcDetails] = useState([])

    useEffect(() => {
        let arr = cloneDeep(data?.sisterStores);
        arr.forEach(item => {
            item.department = item.department?.length > data.filterData?.l2_name?.length ? "All Departments" : item.department
            // item.seasoncode = item.seasoncode?.length > data.filterData?.assortment_indicator?.length ? "All Assort Indicators" : item.seasoncode
        })
        setData(arr)
        let allDc = data.dcDetails?.map(item => item.dc)
        let rows = {}
        if(data.selectedDc?.length) {
            data.selectedDc?.forEach(dc => {
                if(allDc.indexOf(dc) >= 0) {
                    rows[allDc.indexOf(dc)] = true
                }
            })
        }
        else {
            if(data.selectedStore?.primary_dc?.length) {
                data.selectedStore?.primary_dc?.forEach(dc => {
                    if(allDc.indexOf(dc.value) >= 0) {
                        rows[allDc.indexOf(dc.value)] = true
                    }
                })
            }
            if(data.selectedStore?.secondary_dc?.length) {
                data.selectedStore?.secondary_dc?.forEach(dc => {
                    if(allDc.indexOf(dc.value) >= 0) {
                        rows[allDc.indexOf(dc.value)] = true
                    }
                })
            }
        }
        let dcValues = data.selectedStore?.primary_dc.concat(data.selectedStore?.secondary_dc).map(dc => dc?.value)
        setDcDetails(data?.dcDetails.filter(item => dcValues.includes(item.dc)))
        setInitialSelectedRows(rows)
        saveDCSource({data: dcValues.filter(dc => dc !== "*")})
        // setDcSrcRowsSelection(inst.getSelectedRows);
    }, [data])

    const sisterStoreTableColumns = [
        {
            sticky: "left",
            Header: TABLE_HEADERS.STORE_CODE,
            accessor: 'store_code',
            Filter: ColumnFilter,
        },
        {
            Header: TABLE_HEADERS.STORE_NAME,
            accessor: 'store_name',
            Filter: ColumnFilter,
            width: 250
        },
        {
            Header: TABLE_HEADERS.REGION,
            accessor: 'region',
            Filter: ColumnFilter,
        },
        {
            Header: TABLE_HEADERS.ASSORT,
            accessor: 'seasoncode',
            Cell: (instance) => <ReactTollTip {...instance} />,
            Filter: ColumnFilter,
            width: 300
        },
        {
            Header: TABLE_HEADERS.DEPARTMENT,
            accessor: 'department',
            Cell: (instance) => <ReactTollTip {...instance} />,
            Filter: ColumnFilter,
            width: 300
        }
    ]

    const dcColumns = [
        {
            Header: "DC ID",
            accessor: 'dc',
            Filter: ColumnFilter,
            width: 250
        },
        {
            Header: "DC Name",
            accessor: 'dc_name',
            Filter: ColumnFilter,
            width: 200
        },
        // {
        //     Header: "State",
        //     accessor: 'state',
        //     Filter: ColumnFilter,
        // },
        {
            Header: "Country",
            accessor: 'country',
            Filter: ColumnFilter,
        },
        {
            Header: "Lead Time",
            accessor: 'transit_time',
            Cell: ({value, row}) => <span>{data?.leadTimeDcMapping?.[row?.original?.dc] || value}</span>,
            Filter: ColumnFilter,
        },
        // {
        //     Header: "Distance (Miles)",
        //     accessor: 'distance',
        //     Filter: ColumnFilter,
        // }
    ]

    const onDCSourcingInventoryMount = (inst) => {
        // saveDCSource({data: inst?.selectedFlatRows.map(item => item?.original?.dc)})
        // setDcSrcRowsSelection(inst.getSelectedRows);
    }

    return (
        <>
            <section className="section my-4 m-0">
                <h3 className="fnt-bold m-0 py-3">Sister Stores</h3>
                <hr className="m-0 mb-2" />

                {/* {(dataError) ? (<div className="error">{dataError}</div>) :
                    (data ? ( */}
                <ReactTableWithPlugins
                    shouldPagination
                    data={(sisterStoreData || [])}
                    columns={sisterStoreTableColumns}
                    renderMarkup='TableMarkup'
                    keyRT="sortAndSearch"
                    tableId="newstore_sister_stores"
                />
                {/* ) : null)} */}
            </section>
            <section className="section mt-4 m-0">
                <h3 className="fnt-bold m-0 py-3">DC Source</h3>
                <hr className="m-0 mb-2" />
                {/* <PageLoader loader={dataLoading} gridLoader={true}> */}
                {/* {(dataError) ? (<div className="error">{dataError}</div>) :
                    (data ? ( */}
                <ReactTableWithPlugins
                    shouldPagination
                    data={(dcDetails?.length ? dcDetails : [])}
                    columns={dcColumns}
                    renderMarkup='TableMarkup'
                    // features={["CHECKBOX_FOR_FIRSTCOLUMN"]}
                    keyRT="sortAndSearch"
                    tableId="newstore_dcTable"
                    getInstanceOnMount={(inst) => onDCSourcingInventoryMount(inst)}
                    initialSelectedRows={initialSelectedRows}
                />
                {/*  ) : null)} */}
                {/* </PageLoader> */}
            </section>
        </>
    )
}

export default DetailsTable;