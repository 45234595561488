import React, { useEffect } from "react";
import XLSX from 'xlsx'
import XML from 'xml-js'
import { forEach } from "lodash";
import { saveFile } from '../../utils/fileSave'

const ExportFile = React.memo((props) => {
    const {
      fileName,
      text,
      data,
      type,
      callFunc,
      sheetName,
      downloadExcelData,
      formateXLSX,
      excelColumnsToBeFormatted,
      headers,
      columnOrder,
      tableId
    } = props;

    //Function to find the indices of the columns to be hidden
    function findColumnIndex(worksheet, columnName) {
      const range = XLSX.utils.decode_range(worksheet['!ref']);
      for (let C = range.s.c; C <= range.e.c; ++C) {
        const cellAddress = XLSX.utils.encode_cell({ r: range.s.r, c: C });
        const cell = worksheet[cellAddress];
        const columnKey = Object.keys(data[0])[C];
        if (cell && columnKey === columnName) {
          return C;
        }
      }
    }
    
    //Set the cell value to " " for columns to be hidden
    function hideColumns(worksheet, columnIndices) {
      columnIndices.forEach(columnIndex => {
        const range = XLSX.utils.decode_range(worksheet['!ref']);
        for (let R = range.s.r; R <= range.e.r; ++R) {
          const cellAddress = XLSX.utils.encode_cell({ r: R, c: columnIndex });
          if (worksheet[cellAddress]) {
            // Hide the column by setting the cell format to hidden
            worksheet[cellAddress] = { t: 'z', v: '', s: { hidden: true } };
          }
        }
      });
    }
   
    //copy the non-empty column to a new sheet
    function removeEmptyColumns(worksheet) {
      const range = XLSX.utils.decode_range(worksheet['!ref']);
      const newWorksheet = {};
      let newColumnIndex = 0;
    
      for (let C = range.s.c; C <= range.e.c; ++C) {
        let isColumnEmpty = true;
    
        for (let R = range.s.r; R <= range.e.r; ++R) {
          const cellAddress = XLSX.utils.encode_cell({ r: R, c: C });
          if (worksheet[cellAddress] && worksheet[cellAddress].v) {
            isColumnEmpty = false;
            break;
          }
        }
    
        if (!isColumnEmpty) {
          for (let R = range.s.r; R <= range.e.r; ++R) {
            const oldCellAddress = XLSX.utils.encode_cell({ r: R, c: C });
            const newCellAddress = XLSX.utils.encode_cell({ r: R, c: newColumnIndex });
            newWorksheet[newCellAddress] = worksheet[oldCellAddress];
          }
          newColumnIndex++;
        }
      }
      if(newColumnIndex === 0) {
        return {};
      }
    
      // Copy other properties from the original worksheet to the new worksheet
      newWorksheet['!ref'] = XLSX.utils.encode_range({
        s: { r: range.s.r, c: 0 },
        e: { r: range.e.r, c: newColumnIndex - 1 }
      });
      newWorksheet['!merges'] = worksheet['!merges'];
    
      return newWorksheet;
    }

    const downloadExcel = () => {
        if (data.length) {
            let options = {};
            columnOrder?.length && (options["header"] = columnOrder);
            const worksheet = XLSX.utils.json_to_sheet(data, options)
            headers?.length && XLSX.utils.sheet_add_aoa(worksheet, [headers], { origin: "A1" });
            if (formateXLSX) {
              let range = XLSX.utils.decode_range(worksheet["!ref"]);

              // This function formats the numbers and add $ for currency coloumns.
              forEach(excelColumnsToBeFormatted, (C) => {
                for (let R = range.s.r; R <= range.e.r; ++R) {
                  let addr = XLSX.utils.encode_cell({
                    r: R + 1,
                    c: C.value ? C.value : C,
                  });

                  if (worksheet[addr] && worksheet[addr].v && worksheet[addr].v != "-") {
                    worksheet[addr].t = "n";
                    if (C.isCurruncy) {
                      worksheet[addr].z = "$ #,##0.00";
                    } else if (!C?.isFractional) {
                      worksheet[addr].z = "#,##0";
                    } else {
                      worksheet[addr].z = "#,##0.00";
                    }
                  }
                }
              });
            }

          let arr =[];
          const columnPreferences = JSON.parse(localStorage.getItem("column_preferences"));
          
          if(Array.isArray(columnPreferences.hiddenColumns[tableId])){
            columnPreferences.hiddenColumns[tableId]?.forEach((ele) => arr.push(ele?.value));
          }else {
            columnPreferences.hiddenColumns.global?.forEach((ele) => arr.push(ele.value));
          }
          
          
         
          const processedArray = arr.map(item => {
            const parts = item.split('-'); 
            const lastPart = parts[parts.length - 1].trim(); 
            return lastPart;
          });
          


          const columnIndicesToBeHidden = processedArray.map((value) => findColumnIndex(worksheet, value));
          let newWorksheet= {};
          if(columnIndicesToBeHidden.length > 0){
            hideColumns(worksheet, columnIndicesToBeHidden);
            newWorksheet = removeEmptyColumns(worksheet);
          }

            const workbook = {}
            if(sheetName){
                workbook.Sheets = {
                    [sheetName] : Object.keys(newWorksheet).length > 0 ? newWorksheet : worksheet
                }
                workbook.SheetNames = [sheetName]
            }
            else {
                workbook.Sheets = {
                    "Sheet1": Object.keys(newWorksheet).length > 0 ? newWorksheet : worksheet
                }
                workbook.SheetNames = ["Sheet1"]
            }
            const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" })
            saveFile(excelBuffer, `${fileName}.xlsx`)
        }
    }

    const downloadXml = () => {
        var result = XML.json2xml(data, { compact: true });
        saveFile(result, `${fileName}.xml`)
    }

    const options = {
        "1": downloadExcel,
        "2": downloadXml
    }

    useEffect(() => {
        if(props.downloadExcelData) {
            downloadExcel()
        }
    }, [props.downloadExcelData])

    return (
        <button className="btn btn-primary px-2" onClick={callFunc ? callFunc : options[type]}>
            <i
                className="fa fa-download mr-2"
                title="Configure"
                aria-hidden="true"
            ></i>
            {text}
        </button>
    )
})

export default ExportFile;
