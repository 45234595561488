export const calculateMaximumEaches = (
  editData,
  tableData,
  dcAvailableMap,
  instance,
  dcKey,
  size,
  index
) => {
  const parentRowId = Number(instance.row.id.split(".").slice(0, -1).join(".")); // string
  const row = instance.row.original;

  const store_code = tableData?.[parentRowId].store_code;

  return (
    (editData?.[row.article]?.[store_code]?.[dcKey]?.[size] >= 0
      ? editData?.[row.article]?.[store_code]?.[dcKey]?.[size]
      : row.eaches_size_value?.[dcKey]?.[index]) +
    (dcAvailableMap?.[row.article]?.[dcKey]?.[size] >= 0
      ? dcAvailableMap[row.article]?.[dcKey]?.[size]
      : row.eaches_available?.[dcKey]?.[size])
  );
};

export const calculateMaximumPacks = (
  editData,
  tableData,
  dcAvailableMap,
  instance,
  dcKey
) => {
  const parentRowId = Number(instance.row.id.split(".").slice(0, -1).join(".")); // string
  const row = instance.row.original;

  const store_code = tableData?.[parentRowId].store_code;

  return (
    (editData?.[row.article]?.[store_code]?.[dcKey]?.[row.packs] >= 0
      ? editData?.[row.article]?.[store_code]?.[dcKey]?.[row.packs]
      : (row.packs_allocated_dc[dcKey] || 0)) + 
      (dcAvailableMap?.[row.article]?.[dcKey]?.[row.packs] >=0 
        ? dcAvailableMap?.[row.article]?.[dcKey]?.[row.packs]
        : (row.packs_available[dcKey] || 0) )
  );
};
