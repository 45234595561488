import * as actionTypes from "./ActionTypes"

/*
Function to dispatch filter options
*/

export const fetchFilterData = (payload, filterKey) => ({
    type: actionTypes.FETCH_FILTER_DATA,
    payload,
    filterKey
})

/*
Function to dispatch get excess inv data
*/

export const getExcessInvData = (payload) => ({
    type: actionTypes.GET_EXCESS_DATA,
    payload
})

export const getExcessGraphData = (payload) => ({
    type: actionTypes.GET_EXCESS_GRAPH_DATA,
    payload
})

/*
Function to reset the data
*/

export const resetExcessInv = () => ({
    type: actionTypes.RESET_ALL
})

/*
Function to reset the filters
*/

export const resetFiltersOptions = () => ({
    type: actionTypes.RESET_FILTERS_DATA,
})

/*
Function to generate excel
*/

export const generateExcel = (payload) => ({
    type: actionTypes.GENERATE_EXCEL,
    payload
})

export const fetchExcelDataSuccesss = (payload) => ({
    type: actionTypes.GENERATE_EXCEL_SUCCESS,
    payload,
  });
  
  export const fetchExcelDataError = (payload) => ({
    type: actionTypes.GENERATE_EXCEL_ERROR,
    payload,
  })