import React, { useEffect, useState, forwardRef, useRef, useImperativeHandle } from "react";
import "./Drafts.css";
import { connect } from "react-redux";
import ReactTable from "../../components/Table/ReactTable";
import WithDynamicPlugins from "../../components/Table/WithPlugins";
import PageLoader from '../../components/Loader/PageLoader';
import { getDrafts, resetAll } from './DraftsAction'
import Notification from "../../components/Notification/Notifications";
import * as Notify from "../../components/Notification/Notifications";
import ColumnFilter from "../UserManagement/ColumnFilter";
import { cloneDeep } from "lodash"
import { numberUSFormatting } from "../../utils/formatters/valueFormatters";

const ReactTableWithPlugins = WithDynamicPlugins(ReactTable);

const Drafts = (props) => {

    const handleContinueAllocation = (row) => {
        props.continueAllocation(row.original)
    }
    useEffect(() => {
        if (props.isNewDraftSaved || !props.draftsData.length || props.isFirstDraftsCall) {
            props.setIsNewDraftSaved(false)
            let user_id = Number(localStorage?.getItem("user_id")) ? Number(localStorage.getItem("user_id")) : ""
            props.getDrafts({ user_id })
        }
    }, [props.isNewDraftSaved, props.isFirstDraftsCall]);

    const cols = [
        {
            Header: "Plan ID/Name",
            accessor: "allocation_name",
            sticky: "left",
            Filter: ColumnFilter,
            width: 250
        },
        {
            Header: "Created By",
            accessor: "created_by",
            Filter: ColumnFilter,
            width: 250
        },
        {
            Header: "Creation Date",
            accessor: "created_at",
            Filter: ColumnFilter,
        },
        {
            Header: "Creation Time",
            accessor: "creation_time",
            Filter: ColumnFilter,
        },
        {
            Header: "Last Modified",
            accessor: "updated_at",
            Filter: ColumnFilter,
        },
        {
            Header: "Styles Allocated",
            accessor: "styles",
            Cell: (inst) => numberUSFormatting(inst?.value),
            Filter: ColumnFilter,
        },
        {
            Header: "# Stores",
            accessor: "stores",
            Filter: ColumnFilter,
        },
        {
            Header: "Actions",
            accessor: "actions",
            sticky: "right",
            Cell: (instance) => (
                <div>
                    <button
                        className="btn btn-outline-secondary btn-sm px-2 rounded-lg text-body"
                        onClick={() => { handleContinueAllocation(cloneDeep(instance.row)) }}
                    >
                        Continue Allocation
                    </button>
                </div>
            ),
            // width: 150,
            disableFilters: true
        },
    ]

    return (
        <PageLoader loader={props.draftsDataLoading} gridLoader={true}>
            {/* <Notification /> */}
            {(props.draftsDataError) ? (<div className="error">Something Went Wrong!!</div>) :
                <ReactTableWithPlugins
                    // getInstanceOnMount={(inst) => {
                    //     setTableInstance(() => inst);
                    // }}
                    sortBy={[{ id: 'updated_at', desc: true }, { id: 'created_at', desc: true }]}
                    columns={cols}
                    data={props.draftsData.length ? props.draftsData : []}
                    renderMarkup="TableMarkup"
                    keyRT="sortAndSearch"
                    shouldPagination
                    totalRecordsLen={props.draftsData.length}
                    tableId="drafts"
                />}
        </PageLoader>
    )
}


const mapStateToProps = ({ drafts }) => {
    return {
        draftsData: drafts.draftsData,
        draftsDataLoading: drafts.draftsDataLoading,
        draftsDataError: drafts.draftsDataError
    }
};

const mapDispatchToProps = (dispatch) => ({
    getDrafts: (payload) => dispatch(getDrafts(payload)),
    resetAll: () => dispatch(resetAll())
});

export default connect(mapStateToProps, mapDispatchToProps)(Drafts);