import { getLabel } from "../../../utils/commonUtilities";
import { updateArticleName } from "../../../utils/filterLevelMapping";

export const DAILY_SUMMARY_TABLE_HEADERS = {
    ARTICLE_ID: `${updateArticleName(false)} ID`,
    STYLE_ID: "Style ID",
    COLOR_ID: "Color ID",
    STYLE_DESCRIPTION: "Style Description",
    STYLE_COLOR: "Style Color",
    COLOR: "Color",
    LEVEL1: "Channel",
    LEVEL2: "Department",
    LEVEL3: "Class",
    LEVEL4: "Sub Class",
    LEVEL5: "Silhouette",
    LEVEL6: "Collection",
    ALLOCATION_ID: "Plan Name",
    INVENTORY_SOURCE: "Inv. Source",
    UNITS_ALLOCATED: "Units Allocated",
    DC_UNITS_OH: "Net Available Inv"
};

export const DAILY_SUMMARY_SKU_HEADERS = {
    ALLOCATION_ID: "Plan Name",
    INVENTORY_SOURCE: "Inv. Source",
    STORE_NUMBER: "Store Number"
}

export const CARDS = {
    CARD1: "# of Allocation",
    CARD2: "# of Style colors Allocation",
    CARD3: "# of Units Allocation",
    CARD4: "Net Available Inv",
    CARD5: "Reserve Quantity",
}