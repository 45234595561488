import { takeLatest, takeEvery, put, call, all, delay } from "redux-saga/effects";
import * as actionTypes from "../actions/DCConfigAction/DCConfigActionTypes"
import {
    getSisterStoreFilters, upsertNewStoreDetails, getNewReservedArticleList, getReservedArticlesInfo, deleteNewStore, getStoreGrades,
    getNewStorePastAllocations
} from "../../../routes/api";
import { cloneDeep } from "lodash";
import moment from "moment"
import { dateFormat } from "../../../utils/commonUtilities";

function* getSisterStoreFiltersData(action) {
    try {
        let index;
        if(action?.payload?.hasOwnProperty("index")) {
            index = `${action?.payload?.index}`
            delete action?.payload?.index
        }
        const res = yield call(getSisterStoreFilters, action.payload);
        let { data, message, status, existing_filters, approved } = res.data
        if (status) {
            let filters = {
                l2_name: data[0].l2_name.map(item => { return { label: item, value: item } }),
                stores: data[0].stores,
                // assortment_indicator: data[0].assortment_indicator.map(item => { return { label: item, value: item } })
            }
            if(action.payload?.l2_name) {
                if(action.payload?.l2_name?.length) {
                    filters["assortment_indicator"] = data[0].assortment_indicator.map(item => { return { label: item, value: item } })
                    yield put({ type: actionTypes.GET_SISTER_STORE_FILTERS_SUCCESS, assort: action.payload?.l2_name, data: filters["assortment_indicator"], index });
                }
            }
            else {
                yield put({ type: actionTypes.GET_SISTER_STORE_FILTERS_SUCCESS, data: filters });
            }
            if(existing_filters.length) {
                yield put({ type: actionTypes.SAVE_SISTER_STORE_FILTERS, payload: existing_filters, approved });
            }
        }
        else {
            yield put({ type: actionTypes.GET_SISTER_STORE_FILTERS_ERROR, error: message ? message : "Something Went Wrong!!" });
        }
    } catch (error) {
        yield put({ type: actionTypes.GET_SISTER_STORE_FILTERS_ERROR, error: "Something Went Wrong!!" });
    }
}

function* upsertNewStoreDetailsWorker(action) {
    if(!action.payload.secondary_dc) {
        delete action.payload.secondary_dc
    }
    try {
        const res = yield call(upsertNewStoreDetails, action.payload);
        let { data, message, status } = res.data
        if (status) {
            yield put({ type: actionTypes.UPSERT_NEW_STORE_DETAILS_SUCCESS });
        }
        else {
            yield put({ type: actionTypes.UPSERT_NEW_STORE_DETAILS_ERROR, error: message ? message : "Something Went Wrong!!" });
        }
    } catch (error) {
        yield put({ type: actionTypes.UPSERT_NEW_STORE_DETAILS_ERROR, error: "Something Went Wrong!!" });
    }
}

function* getNewReservedArticleData(action) {
    try {
        const { payload } = action;

        const res = yield call(getNewReservedArticleList, payload);
        if (res.data.status) {
            yield put({ type: actionTypes.GET_NEW_RESERVED_ARTICLE_LIST_SUCCESS, data: res?.data?.new_reserved_article_details });
        }
        else {
            yield put({ type: actionTypes.GET_NEW_RESERVED_ARTICLE_LIST_ERROR, error: res?.data?.message ? res.data.message : "Something Went Wrong!!" });
        }
    } catch (error) {
        yield put({ type: actionTypes.GET_NEW_RESERVED_ARTICLE_LIST_ERROR, error: "Something Went Wrong!!" });
    }
}

function* deleteNewStoreWorker(action) {
    try {
        const { payload } = action;

        const res = yield call(deleteNewStore, payload);
        if (res.data.status) {
            yield put({ type: actionTypes.DELETE_NEW_STORE_SUCCESS, data: res?.data?.data });
        }
        else {
            yield put({ type: actionTypes.DELETE_NEW_STORE_ERROR, error: res?.data?.message ? res.data.message : "Something Went Wrong!!" });
        }
    } catch (error) {
        yield put({ type: actionTypes.DELETE_NEW_STORE_ERROR, error: "Something Went Wrong!!" });
    }
}

function* getReservedArticlesByStore(action) {
    try {
        const { payload } = action;

        const res = yield call(getReservedArticlesInfo, payload);
        let data = cloneDeep(res?.data?.reserved_article_info )
        data.map(item => {
            item.ros = Number(item.ros) ? Number(item.ros).toFixed(2) : item.ros
            item.aps = Number(item.aps) ? Number(item.aps).toFixed(2) : item.aps
            item.demand_estimated = Number(item.demand_estimated) ? Number(item.demand_estimated).toFixed(2) : item.demand_estimated
        })
        if (res.data.status) {
            yield put({ type: actionTypes.GET_RESERVED_ARTICLES_SUCCESS, data});
        }
        else {
            yield put({ type: actionTypes.GET_RESERVED_ARTICLES_ERROR, error: res?.data?.message ? res.data.message : "Something Went Wrong!!" });
        }
    } catch (error) {
        yield put({ type: actionTypes.GET_RESERVED_ARTICLES_ERROR, error: "Something Went Wrong!!" });
    }
}

function* getStoreGradesWorker(action) {
    try {
        const { payload } = action;

        const res = yield call(getStoreGrades, payload);

        if (res.data.status) {
            let data = cloneDeep(res?.data?.data).map(opt => {return {label: opt, value: opt}})
            yield put({ type: actionTypes.GET_STORE_GRADES_SUCCESS, data});
        }
        else {
            yield put({ type: actionTypes.GET_STORE_GRADES_ERROR, error: res?.data?.message ? res.data.message : "Something Went Wrong!!" });
        }
    } catch (error) {
        yield put({ type: actionTypes.GET_STORE_GRADES_ERROR, error: "Something Went Wrong!!" });
    }
}
function* getNewStoreAllocationsWorker(action) {
    try {
        const { payload } = action;

        const res = yield call(getNewStorePastAllocations, payload);

        if (res.data.status) {
            let allocationPlans = cloneDeep(res.data?.data)
            allocationPlans.forEach(plan => {
                let timeStamp = moment(plan.creation_date)
                plan.creation_date = dateFormat(plan.creation_date)
                plan.creation_time = timeStamp.format('HH:mm:ss')
                if(plan?.release_date) {
                    let releasedTimeStamp = moment(plan.release_date)
                    plan.released_date_picked = dateFormat(plan.release_date)
                    plan.released_time = releasedTimeStamp.format('HH:mm:ss')
                }
            })
            yield put({ type: actionTypes.GET_NEW_STORE_PAST_ALLOCATIONS_SUCCESS, data: allocationPlans});
        }
        else {
            yield put({ type: actionTypes.GET_NEW_STORE_PAST_ALLOCATIONS_ERROR, error: res?.data?.message ? res.data.message : "Something Went Wrong!!" });
        }
    } catch (error) {
        yield put({ type: actionTypes.GET_NEW_STORE_PAST_ALLOCATIONS_ERROR, error: "Something Went Wrong!!" });
    }
}

function* getSisterStoreFiltersWatcher() {
    yield takeEvery(actionTypes.GET_SISTER_STORE_FILTERS, getSisterStoreFiltersData)
}

function* upsertNewStoreDetailsWatcher() {
    yield takeLatest(actionTypes.UPSERT_NEW_STORE_DETAILS, upsertNewStoreDetailsWorker)
}

function* getNewReservedArticleListWatcher() {
    yield takeLatest(actionTypes.GET_NEW_RESERVED_ARTICLE_LIST, getNewReservedArticleData)
}

function* getReservedArticlesByStoreWatcher() {
    yield takeLatest(actionTypes.GET_RESERVED_ARTICLES, getReservedArticlesByStore)
}

function* deleteNewStoreWatcher() {
    yield takeLatest(actionTypes.DELETE_NEW_STORE, deleteNewStoreWorker)
}

function* getStoreGradesWatcher() {
    yield takeLatest(actionTypes.GET_STORE_GRADES, getStoreGradesWorker)
}
function* getNewStoreAllocationsWatcher() {
    yield takeLatest(actionTypes.GET_NEW_STORE_PAST_ALLOCATIONS, getNewStoreAllocationsWorker)
}

export function* dcConfigSaga() {
    yield all([
        getSisterStoreFiltersWatcher(),
        upsertNewStoreDetailsWatcher(),
        getNewReservedArticleListWatcher(),
        getReservedArticlesByStoreWatcher(),
        deleteNewStoreWatcher(),
        getStoreGradesWatcher(),
        getNewStoreAllocationsWatcher()
    ])
}