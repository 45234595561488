import { takeLatest, takeEvery, put, call, all, delay } from "redux-saga/effects";
import * as actionTypes from "./Actions/ActionTypes"
import {
    getExcessTableData, getExcessGraphData, getExcessFilters
} from "../../../routes/api";
import { isEmpty, reverse } from "lodash";
import { updateArticleName } from "../../../utils/filterLevelMapping";
import { addDollar, numberUSFormatting } from "../../../utils/formatters/valueFormatters";
import { flattenArray } from "../../../utils/commonUtilities";

const g_level_mappind_excess_data = {
    "level1": "l1_name",
    "level2": "l2_name",
    "level3": "l3_name",
    "level4": "l4_name",
    "level5": "l5_name",
    "level6":"l6_name",
    "level7":"l7_name",
    "article":"article",
    "style":"style",
    "store":"store",
    "color":"color",
    "selected_fw":"selected_fw",
    "selected_fy":"selected_fy",
    "row_count":"row_count",
    "row_index":"row_index",
    "sortColumn": "sortColumn",
    "searchColumns": "searchColumns"
  }

function* fetchFilterDataWorker(action) {
    const level_ln_map = {
        "level1": "l1_name",
        "level2": "l2_name",
        "level3": "l3_name",
        "level4": "l4_name",
        "level5": "l5_name",
        "level6":"l6_name",
        "level7":"l7_name",
        "article":"article",
        "style":"style",
        "store":"store",
        "color":"color",
      }
    try {
        const { payload, filterKey } = action;
        let req = {};
        if (!isEmpty(payload)) {
            for (const key in payload) {
                if (payload[key] && payload[key].length > 0)
                    // req[key] = payload[key]?.map((ele) => ele.value)
                    payload[key] && (req[level_ln_map[key]] = flattenArray(payload[key])?.map((ele) => ele.value))
            }
        }
        const res = yield call(getExcessFilters, req);
        if (res.data.status) {
            const data = {};
            data["filterMapping"] = res.data?.filterMapping

            for (const key in res.data.data[0]) {
                let k = "";
                if (key === "l1_name") {
                    k = "departmentOptions";
                } else if (key === "l2_name") {
                    k = "genderOptions";
                } else if (key === "l3_name") {
                    k = "rbuOptions";
                } else if (key === "l4_name") {
                    k = "dcsOptions";
                } else if (key === "l5_name") {
                    k = "level5Options";
                } else if (key === "l6_name") {
                    k = "level6Options";
                } else if (key === "l7_name") {
                    k = "level7Options";
                }else if (key === "store") {
                    k = "storeOptions";
                } else if (key === "article") {
                    k = "articleOptions"
                } else if (key === "color") {
                    k = "colorOptions"
                } else if (key === "style") {
                    k = "styleOptions"
                }

                // data["departmentOptions"] = [{ label: "Accessories", value: "Accessories" }, { label: "Apparel", value: "Apparel" }, { label: "Footwear", value: "Footwear" }]

                data[k] = res.data.data[0][key]?.filter(val => val)?.map((element) => ({
                    value: element,
                    label: element,
                }));
            }

            yield put({ type: actionTypes.FETCH_FILTER_SUCCESS, data: data, key: filterKey });
        }
    } catch (error) {
        yield put({ type: actionTypes.FETCH_FILTER_ERROR, error: "Something went wrong!" });
    }
}

function* getExcessInvDataWorker(action) {
    try {
        const { payload } = action;
        let req = {}
        for (let key in payload) {
            if (payload[key]) {
                payload[key] && (req[g_level_mappind_excess_data[key]] = payload[key])
            }
        }
        req.row_index = payload.row_index


        const res = yield call(getExcessTableData, req);
        if (res.data.status) {
            const { count, inv_cost_sum, inv_sum } = res.data.global_data

            yield put({ type: actionTypes.GET_EXCESS_DATA_SUCCESS, tableData: res.data.data, totalCount: count, sum: isEmpty(payload.searchColumns) && isEmpty(payload.sortColumn) ? { inv_cost_sum, inv_sum } : {}, nextIndex: payload.row_index + 100 });
        }
        else {
            yield put({ type: actionTypes.GET_EXCESS_DATA_ERROR, error: "Something went wrong!" });
        }
    } catch (error) {
        yield put({ type: actionTypes.GET_EXCESS_DATA_ERROR, error: "Something went wrong!" });
    }
}

function* generateExcelWorker(action) {
    try {
        const { payload } = action;
        let req = {}
        
        for (let key in payload) {
            if (payload[key]) {
                payload[key] && (req[g_level_mappind_excess_data[key]] = payload[key])
            }
        }
        req.row_index = payload.row_index

        const res = yield call(getExcessTableData, req);
        if (res.data.status) {
            const { count } = res.data.global_data

            let excelData = []
            res.data.data.map(item => {
                excelData.push({
                    "store_code": item.store_code,
                    "store_name": item.store_name,
                    "fiscal_week": item.fiscal_week,
                    "fiscal_year": item.fiscal_year,
                    "article": item.article,
                    "style_code": item.style_code,
                    "color_code": item.color_code,
                    "style_name": item.style_name,
                    "color_desc": item.color_desc,
                    "Color": item.color,
                    "inv": item.inv,
                    "ros": item.ros,
                    "units": item.units,
                    "wos": item.wos,
                    "target_wos": item.target_wos,
                    "excess_inv": item.excess_inv,
                    "excess_inv_cost": item.excess_inv_cost
                })
            })
            yield put({ type: actionTypes.GENERATE_EXCEL_SUCCESS, excelData,totalCountExcel: count, nextIndexExcel: payload.row_index + 100 });

        }
        else {
            yield put({ type: actionTypes.GENERATE_EXCEL_ERROR, error: "Something went wrong!" });
        }
    }
    catch (error) {
        yield put({ type: actionTypes.GENERATE_EXCEL_ERROR, error: "Something went wrong!" });
    }
}

function* getExcessGraphDataWorker(action) {
    try {
        const { payload } = action;
        let req = {}
        for (let key in payload) {
            if (payload[key]) {
                req[g_level_mappind_excess_data[key]] = payload[key]
            }
        }
        const res = yield call(getExcessGraphData, req);
        if (res.data.status) {

            let graphData = {
                series: [{ name: "Excess Inventory", data: [] },
                { name: "Inventory", data: [] },
                { name: "Units Sold", data: [] }],

                labels: [],
                yearData: [],
            }

            res.data.data.reverse().map(item => {
                graphData.series[0].data.push(item.excess_inv_sum)
                graphData.series[1].data.push(item.inv_sum)
                graphData.series[2].data.push(item.unit_sold_sum)

                if (!graphData.labels.includes(item.week)) {
                    graphData.labels.push(item.week)
                }
                if (!graphData.yearData.includes(item.week)) {
                    graphData.yearData.push({ week: item.week, year: item.year });
                }
            })


            yield put({ type: actionTypes.GET_EXCESS_GRAPH_DATA_SUCCESS, graphData, weeks: graphData.labels, yearData: graphData.yearData });
        }
        else {
            yield put({ type: actionTypes.GET_EXCESS_GRAPH_DATA_ERROR, error: "Something went wrong!" });
        }
    } catch (error) {
        yield put({ type: actionTypes.GET_EXCESS_GRAPH_DATA_ERROR, error: "Something went wrong!" });
    }
}

// function* fetchFilterDataWatcher() {
//     yield takeLatest(actionTypes.FETCH_FILTER_DATA, fetchFilterDataWorker)
// }

function* getExcessInvDataWatcher() {
    yield takeLatest(actionTypes.GET_EXCESS_DATA, getExcessInvDataWorker)
}

function* getExcessGraphDataWatcher() {
    yield takeLatest(actionTypes.GET_EXCESS_GRAPH_DATA, getExcessGraphDataWorker)
}

function* fetchFilterDataWatcher() {
    yield takeEvery(actionTypes.FETCH_FILTER_DATA, fetchFilterDataWorker)
}

function* generateExcelWatcher() {
    yield takeLatest(actionTypes.GENERATE_EXCEL, generateExcelWorker)
}


export function* excessSaga() {
    yield all([
        // fetchFilterDataWatcher(),
        getExcessInvDataWatcher(),
        getExcessGraphDataWatcher(),
        fetchFilterDataWatcher(),
        generateExcelWatcher()
    ])
}