import { takeLatest, put, all, call, takeEvery } from "redux-saga/effects";
import {
  fetchDeepDiveDataError,
  fetchDeepDiveDataSuccess,
  fetchExcelDataError,
  fetchExcelDataSuccesss,
  fetchFilterDataError,
  fetchFilterDataSuccesss,
  FETCH_FILTER_DATA,
  FETCH_TABLE_DATA,
  GENERATE_EXCEL,
} from "./DeepDiveAction";


import { isEmpty } from "lodash";
import { getDeepDiveData, getDeepDiveFilterData } from "../../../routes/api";
import { CARDS, DEEP_DIVE_TABLE_HEADERS } from "./DeepDiveConstants";
import { rowCount } from "./DeepDive";
import { appendPerc, flattenArray, isAshley } from "../../../utils/commonUtilities";

const level_ln_map = {
  "level1": "l1_name",
  "level2": "l2_name",
  "level3": "l3_name",
  "level4": "l4_name",
  "level5": "l5_name",
  "level6":"l6_name",
  "level7":"l7_name",
  "article":"article",
  "color":"color",
}

function* fetchStoreDataWorker(action) {
  try {
    const {payload} = action;
    const {request} = payload;
    const req = {}
    for(let i in request){
      if(!isEmpty(request[i])){
         if(Array.isArray(request[i])){
            req[i] = request[i].map(val => val.value)
         }
         else{
              req[i] = request[i]
         }
      }
    }
    req['rowIndex'] = payload.rowIndex
    req['rowCount'] = payload.rowCount
    if(!isEmpty(payload?.searchTermReq)) {
      req["searchColumns"] = {...payload?.searchTermReq}
    }
    if(!isEmpty(payload?.sortReq)) {
        req["sortColumn"] = {...payload?.sortReq[0]}
    }

    req["refresh_aggregate_data"] = payload?.refresh_aggregate_data

    const res = yield call(getDeepDiveData,req);

    if (res.data.status) {
      const {actual_vs_sales_qty_match,plan_vs_actual_qty_match,tot_actual_allocation,tot_planned_allocaton,plan_vs_actual_size_match,actual_vs_sales_size_match} = res?.data
      const cardData = [
      {
        'title': CARDS.CARD1,
        value:  tot_planned_allocaton
      },
      {
        'title': CARDS.CARD2,
        value:  tot_actual_allocation
      },
      {
        'title': CARDS.CARD3,
        value: appendPerc(plan_vs_actual_qty_match)
      },
      {
        'title': CARDS.CARD4,
        value: appendPerc(actual_vs_sales_qty_match)
      },
      {
        'title': CARDS.CARD5,
        value: appendPerc(plan_vs_actual_size_match)
      },
      {
        'title': CARDS.CARD6,
        value: appendPerc(actual_vs_sales_size_match)
      }]

      yield put(fetchDeepDiveDataSuccess({ data: res.data.deepdive_data, cardData:cardData, totalCount: res.data.totalCount, nextIndex: payload.rowIndex + rowCount, refresh_aggregate_data: payload?.refresh_aggregate_data }));
    } 
    else {
      yield put(fetchDeepDiveDataError({ error: "Something went wrong!" }));
    }
  }
  catch (error) {
    console.log(error,'dcsdc')
    yield put(fetchDeepDiveDataError({ error: "Something went wrong!" }));
  }
 
}

function* fetchFilterDataWorker(action) {
  try {
    const { payload, filterType } = action;
    let req = {};
    if (payload) {
      for (const key in payload) {
        !isEmpty(payload[key]) && (req[level_ln_map[key]] = flattenArray(payload[key]).map((ele) => ele.value))
      }
    }
    if(filterType){
      req["filter_type"] = filterType;
    }
    const res = yield call(getDeepDiveFilterData, req);
    if (res.data.status) {
      const data = {};
      data["filterMapping"] = res.data?.filterMapping
      for (const key in res.data.data[0]) {
      let k = "";
        if (key === "l1_name") {
          k = "departmentOptions";
        } else if (key === "l2_name") {
          k = "genderOptions";
        } else if (key === "l3_name") {
          k = "subCatOptions";
        } else if (key === "l4_name") {
          k = "dcsOptions";
        } else if (key === "l5_name") {
          k = "level5Options";
        } else if (key === "l6_name") {
          k = "level6Options";
        } else if (key === "l7_name") {
          k = "level7Options";
        } else if(key === "color") {
            k = "colorOptions";
        } else if(key === "article") {
          k = "articleIdOptions";
        } 
        else if(key === "style") {
            k = "styleOptions";
        }
         else if(key === "store") {
          k = "storeIdOptions";
        } else if(key === "season_code_active") {
          k = "seasonCodeOptions";
        } else if(key === "season_code_opened") {
          k = "seasonCodeOpenOptions";
        }

        if(Array.isArray(res?.data?.data?.[0]?.[key])) {
          data[k] = res.data.data[0][key]?.filter(val =>val).map((element) => ({
              value: element,
              label: element,
            }));
        }
        else{
          data[k] = []
          for (const [label, value] of Object.entries(res?.data?.data?.[0]?.[key])) {
            data[k].push({
              "label": label,
              "value": value
            })
          }        
      }

      }
        yield put(fetchFilterDataSuccesss({ data: data, key: action?.filterKey?.key }));
      } 
  }
  catch (error) {
    console.log('dd',error)
    yield put(fetchFilterDataError({ error: "Something went wrong!" }));
  }
}

function* generateExcelWorker(action) {
  try {
      const {payload} = action;
      const {request} = payload;

      const req = {}
      for(let i in request){
        if(request[i]){
          if(Array.isArray(request[i])){
              req[i] = request[i].map(val => val.value)
          }
          else{
                req[i] = request[i]
          }
        }
      }    
      req['rowIndex'] = payload.rowIndex
      req['rowCount'] = payload.rowCount

      if(!isEmpty(payload?.searchTermReq)) {
        req["searchColumns"] = {...payload?.searchTermReq}
      }
      const res = yield call(getDeepDiveData, req);
      if (res.data.status) {
          let excelData = []
          res.data.deepdive_data.forEach(item => {
            excelData.push({
              article: item.article,
              style_code: item.style_code,
              color_code: item.color_code,
              style_name: item.style_name,
              color_desc: item.color_desc,
              color: item.color,
              l1_name: item.l1_name,
              l2_name: item.l2_name,
              l3_name: item.l3_name,
              l4_name: item.l4_name,
              l5_name: item.l5_name,
              l6_name: item.l6_name,
              store_code: item.store_code,
              store_name: item.store_name,
              ... isAshley() && {season_code_active: item.season_code_active},
              season_code_opened: item.season_code_opened,
              store_grade: item.store_grade,
              planned_units: item.planned_units,
              allocated_units: item.allocated_units,
              units: item.units,
              planned_perc: item.planned_perc,
              size_perc_planned: item.size_perc_planned,
              match_perc: item.match_perc,
              match_perc_size: item.match_perc_size,
              sku_depth_store: item.sku_depth_store,
              grade_revenue: item.grade_revenue,
              revenue: item.revenue,
              grade_margin: item.grade_margin,
              margin : item.margin,
            })
          })
          yield put(fetchExcelDataSuccesss({data:excelData, totalCountExcel: res.data.totalCount, nextIndexExcel: payload.rowIndex + rowCount }));

      }
      else {
          yield put(fetchExcelDataError({error: "Something went wrong!" }));
      }
  }
  catch (error) {
      yield put(fetchExcelDataError({error: "Something went wrong!" }));
  }
}

function* fetchTableData() {
  yield takeLatest(FETCH_TABLE_DATA, fetchStoreDataWorker);
}

function* fetchFilterDataWatcher() {
  yield takeEvery(FETCH_FILTER_DATA, fetchFilterDataWorker);
}

function* generateExcelWatcher() {
  yield takeLatest(GENERATE_EXCEL, generateExcelWorker)
}

export function* deepDiveSaga() {
  yield all([,
    fetchTableData(),
    fetchFilterDataWatcher(),
    generateExcelWatcher(),
  ]);
}
