import { levelFiltersLabelFormatter, updateArticleName } from "../../../utils/filterLevelMapping";
import moment from "moment";

export const TABLE_COLUMNS = {
    "Article_ID" : `${updateArticleName(false)} ID`,
    "Style_ID": "Style ID",
    "Color_ID": "Color ID",
    "Style": "Style",
    "Style_Color": "Style Color",
    "Global_Color": "Global Color",
    "Level2": levelFiltersLabelFormatter("level2"),
    "Level3": levelFiltersLabelFormatter("level3"),
    "Level4": levelFiltersLabelFormatter("level4"),
    "Level5": levelFiltersLabelFormatter("level5"),
    "Level6": levelFiltersLabelFormatter("level6"),
    "Product_Type": "Product Type",
    "Store_Group" : "Store Groups/ Stores Mapped",
    "Product_Profile": "Product Profile Mapped",
    "DC": "DC Mapped",
    "Old_Style_Mapped": "Old Style Mapped",
    "Auto_Allocation_Status": "Auto Allocation Status",
    "Exotic_Flag": "Exotic Flag",
    "Modified_by": "Modified By",
    "From_Date": "From Date",
    "Upto_Date": "Upto Date"
}

export const ALLOCATION_REQUIRED_OPTIONS = [{
    label: "Active",
    value: "active"
  },
  {
    label: "Deactivated",
    value: "deactivated"
  },
  {
    label: "Paused",
    value: "paused"
  }]

export const EXOTIC_OPTIONS = [{
  label: "Exotic",
  value: "Exotic"
},
{
  label: "Non-Exotic",
  value: "Non-Exotic"
}]
  export const EXCEL_KEYS = ["article", "style_code", "color_code", "style_name", "color_desc", "color", "l2_name", "l3_name", "l4_name", "l5_name", "l6_name", "product_type", "store_group_count", "product_profiles", "dcs_count", "related_article", "auto_allocation_status", "exotic_flag", "updated_by", "from_date", "to_date"]

  export const formatExcelData = (data) => {
    let excelData = [];
    data.forEach(item => {
      excelData.push({
        "article": item.article,
        "style_code": item.style_code,
        "color_code": item.color_code,
        "style_name": item.style_name,
        "color_desc": item.color_desc,
        "color": item.color,
        "l2_name": item.l2_name,
        "l3_name": item.l3_name, 
        "l4_name": item.l4_name, 
        "l5_name": item.l5_name, 
        "l6_name": item.l6_name, 
        "product_type": item.product_type,
        "store_group_count": item?.store_group_count, 
        "product_profiles": item.product_profiles, 
        "dcs_count": item.dcs_count, 
        "related_article": item.related_article, 
        "auto_allocation_status": item.auto_allocation_status, 
        "exotic_flag": item.exotic_flag,
        "updated_by": item.updated_by, 
        "from_date": item.from_date ? moment(item.from_date).format("MM-DD-YYYY") : "", 
        "to_date": item.to_date ? moment(item.to_date).format("MM-DD-YYYY") : "", 
      })
    })
    return excelData;
  }
