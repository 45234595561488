export const EXCEL_COLUMNS_FOR_FORMATTING = {
  lw_revenue_us: {
    value: 19,
    isFractional: true,
    isCurruncy: true,
  },
  lw_margin_us: {
    value: 20,
    isFractional: true,
    isCurruncy: true,
  },
  price_us: {
    value: 22,
    isFractional: true,
    isCurruncy: true,
  },
  lw_revenue_ca: {
    value: 25,
    isFractional: true,
    isCurruncy: true,
  },
  lw_margin_ca: {
    value: 26,
    isFractional: true,
    isCurruncy: true,
  },
  price_ca: {
    value: 28,
    isFractional: true,
    isCurruncy: true,
  },
  lw_revenue: {
    value: 31,
    isFractional: true,
    isCurruncy: true,
  },
  lw_margin: {
    value: 32,
    isFractional: true,
    isCurruncy: true,
  },
  price: {
    value: 34,
    isFractional: true,
    isCurruncy: true,
  },
  tw_revenue: {
    value: 37,
    isFractional: true,
    isCurruncy: true,
  },
  tw_margin: {
    value: 38,
    isFractional: true,
    isCurruncy: true,
  },
  tw_price: {
    value: 40,
    isFractional: true,
    isCurruncy: true,
  },
  ecm_lw_qty_us: 17,
  lw_qty_us: 18,
  ecm_lw_qty_ca: 23,
  lw_qty_ca: 24,
  ecm_lw_qty: 29,
  lw_qty: 30,
  tw_qty: 36,
  bulk_remaining: 41,
  bulk_remaining_mx: 42,
  bulk_remaining_intransit: 43,
  oh: 44,
  ecm_oh: 45,
  oo: 46,
  it: 47,
  wos: {
    value: 48,
    isFractional: true,
  },
  bulk_remaining_us: 50,
  bulk_remaining_intransit_us: 51,
  oh_us: 52,
  ecm_oh_us: 53,
  oo_us: 54,
  it_us: 55,
  wos_us: {
    value: 56,
    isFractional: true,
  },
  bulk_remaining_ca: 58,
  bulk_remaining_intransit_ca: 59,
  oh_ca: 60,
  ecm_oh_ca: 61,
  oo_ca: 62,
  it_ca: 63,
  wos_ca: {
    value: 64,
    isFractional: true,
  },
  stock_out: 66,
  shortfall: 67,
  normal: 68,
  excess: 69,
};

export const LOST_SALES_EXCEL_COLUMNS_FORMATTING = {
  lost_sales: {
    value: 14,
    isFractional: true,
    isCurruncy: true,
  },
};

export const ALLOCATION_DEEP_DIVE_EXCEL_COLUMNS_FORMATTING = {
  sku_depth_store: {
    value: 23,
    isFractional: true,
    isCurruncy: false,
  },
  grade_revenue: {
    value: 24,
    isFractional: true,
    isCurruncy: true,
  },
  revenue: {
    value: 25,
    isFractional: true,
    isCurruncy: true,
  },
  grade_margin: {
    value: 26,
    isFractional: true,
    isCurruncy: true,
  },
  margin: {
    value: 27,
    isFractional: true,
    isCurruncy: true,
  },
};

export const DAILY_SUMMARY_EXCEL_COLUMNS_FORMATTING = {
  units_allocated: 14,
  dc_available: 15
};


export const EXCESS_INV_COLUMNS_FORMATTING = {
  inv: 10,
  ros: {
    value: 11,
    isFractional: true,
    isCurruncy: false,
  },
  unit_sold: 12,
  wos: {
    value: 13,
    isFractional: true,
    isCurruncy: false,
  },
  target_wos: {
    value: 14,
    isFractional: true,
    isCurruncy: false,
  },
  excess_inv: 15,
  excess_inv_cost: {
    value: 16,
    isFractional: true,
    isCurruncy: true,
  },
};

export const DEFAULT_CONSTRAINTS_COLUMNS_FORMATTING = {
  min_stock: 6,
  max_stock: 7,
  wos: 8
}
