import React, { useState } from "react";
import { useHistory } from "react-router";
import POAllocationTable from "./POAllocationTable";
import Modal from "react-awesome-modal";
import Notification from "../../../components/Notification/Notifications";
import * as Notify from "../../../components/Notification/Notifications";
import { FINALIZE_PAGE } from "../../../constants/routeConstants";
import { dateFormat, reloadPageWithUpdateToken } from "../../../utils/commonUtilities";

const StylesPopup = (props) => {
  const { showModal, closeModal, poData } = props;
  const history = useHistory();

  const [tableInstance, setTableInstance] = useState({});

    const release = () => {
        if (tableInstance?.selectedFlatRows.length) {
            let articles = tableInstance?.selectedFlatRows.map(row => row.original.article)
            history.push(FINALIZE_PAGE, {planDetails: { isPOASNFlow: true, articles, allocationCode: poData?.allocation_code, allocationName: poData?.allocation_name, 
                autoApprove: true ,creation_date: poData?.poDetails?.creation_date ||  dateFormat(poData?.created_at) }});
                reloadPageWithUpdateToken()
        }
        else {
            Notify.error("Please select atleast one article to Review!!")
        }
    }
    return (
        <div className="modal__div modal_opacity">
            <Modal
                visible={showModal}
                width="80%"
                effect="fadeInDown"
                onClickAway={closeModal}
            >
                {/* <Notification /> */}
                <div className="modal-container">
                    <div className="modal-header">
                        <div className="row w-100">
                            {
                                process.env.REACT_APP_CLIENT == "ashley" &&
                                <h5 className="page-heading text-center m-0">
                                    PO ID: {poData.po}
                                </h5>
                            }
                        </div>
                        <button type="button" className="close" data-dismiss="modal">
                            <span style={{ cursor: "pointer" }}>
                                <i
                                    className="fa fa-times text-right m-2 "
                                    aria-hidden="true"
                                    onClick={closeModal}
                                />
                            </span>
                        </button>
                    </div>
                    <div className="modal-middle-container modal-body">
                            <div className="mx-2 pr-4 pl-4 row justify-content-center mb-4">
                                <div className="detail__div mr-4">
                                    <span className="fnt-bold mr-2">Allocation Name :</span>
                                    <span>{poData.allocation_name}</span>
                                </div>

              {/* <div className="detail__div mr-4">
                                    <span className="fnt-bold mr-2">DC :</span>
                                    <span>{poData.dc}</span>
                                </div> */}
            </div>
            <div className="mx-2 p-2">
              <POAllocationTable
                isPopup={true}
                data={poData.data}
                setTableInstance={setTableInstance}
                PO={poData.po}
              />
            </div>
          </div>

          <div className="text-center" style={{ padding: "1rem" }}>
            <div className="center">
              <button
                className="btn btn-primary store-grp-modal__btn"
                onClick={closeModal}
              >
                Close
              </button>

              <button className="btn btn-primary fn__btn" onClick={release}>
                Review
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default StylesPopup;
